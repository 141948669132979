import React, { Component, Suspense, lazy } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import "./App.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
// import Cookies from 'universal-cookie';
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { connect } from "react-redux";
import Loading from "./components/lm/UI/Loading";
import Cookies from "js-cookie";

const client_name = Cookies.get("client") || localStorage.getItem("client");

const RialtoManagement = lazy(() =>
  import(`./components/pure/RialtoManagement.jsx`)
);
const PpdAccount = lazy(() => import(`./components/pure/PpdAccount.jsx`));

const ScriptList = lazy(() =>
  import(`./components/${client_name}/Pages/ScriptGenerator/ScriptList`)
);
const ScriptConfiguration = lazy(() =>
  import(
    `./components/${client_name}/Pages/ScriptGenerator/ScriptConfiguration`
  )
);
const ReviewScript = lazy(() =>
  import(`./components/${client_name}/Pages/ScriptGenerator/ReviewScript`)
);
const ReviewPatientScript = lazy(() =>
  import(
    `./components/${client_name}/Pages/ScriptGenerator/ReviewPatientScript`
  )
);
const Checkout = lazy(() =>
  import(`./components/${client_name}/Pages/Checkout/index`)
);
const PracticeRecommendationList = lazy(() =>
  import(
    `./components/${client_name}/Pages/RecommendationListScreen/PracticeRecommendationList`
  )
);
const PatientRecommendationList = lazy(() =>
  import(
    `./components/${client_name}/Pages/RecommendationListScreen/PatientRecommendationList`
  )
);
const PatientScriptList = lazy(() =>
  import(
    `./components/${client_name}/Pages/PatientListScreen/PatientScriptList`
  )
);
const ShippingForm = lazy(() =>
  import(`./components/${client_name}/Pages/Checkout/ShippingForm`)
);
const ReviewOrder = lazy(() =>
  import(`./components/${client_name}/Pages/Checkout/ReviewOrder`)
);
const ScriptDetails = lazy(() =>
  import(`./components/${client_name}/Pages/ScriptDetailScreen/ScriptDetails`)
);
const SmartSearchSupplement = lazy(() =>
  import(`./components/${client_name}/Pages/Supplements/SmartSearchSupplements`)
);
const RecommendationList = lazy(() =>
  import(`./components/${client_name}/Pages/Supplements/RecommendationList`)
);
const PatientRecommendation = lazy(() =>
  import(`./components/pg3/Pages/Supplements/PatientRecommendation`)
);
const ViewRecommendedScreen = lazy(() =>
  import(`./components/pg3/Pages/ScriptDetailScreen/ViewRecommendedScreen`)
);
const OrderDetails = lazy(() =>
  import(`./components/${client_name}/Pages/Checkout/OrderDetails`)
);

const PreLoginCatalog = lazy(() =>
  import("./components/purepro/Pages/PreLoginCatalog/index.js")
);

// const PatientScriptList = lazy(() =>
//   import(`./components/pg3/Pages/PatientListScreen/PatientScriptList`)
// );
const ShowOrderDetails = lazy(() =>
  import(
    `./components/${client_name}/Pages/ScriptDetailScreen/ShowOrderDetails`
  )
);

const ReturnSupplementList = lazy(() =>
  import(
    `./components/${client_name}/Pages/SupplementReturn/ReturnSupplementList`
  )
);

const ReviewReturnItems = lazy(() =>
  import(`./components/${client_name}/Pages/SupplementReturn/ReviewReturnItems`)
);

const PgPatientRecommendationList = lazy(() =>
  import(`./components/pg3/Pages/Supplements/PatientRecommendationList`)
);

class App extends Component {
  dynamic_routes = () => {
    return (
      <div>
        <Switch>
          <Route
            path="/tenants/:tenant_id/patients/:patient_id/rialto_service"
            component={(props) => <RecommendationList {...props} />}
          />
          <Route
            path="/tenants/:tenant_id/patients/:patient_id/catalog"
            component={(props) => <SmartSearchSupplement {...props} />}
          />

          <Route path="/script_list">
            <ScriptList {...this.props} />
          </Route>
          <Route path="/script_config">
            <ScriptConfiguration {...this.props} />
          </Route>
          <Route path="/review_script">
            <ReviewScript {...this.props} />
          </Route>
          <Route path="/review_patient_script">
            <ReviewPatientScript {...this.props} />
          </Route>
          <Route
            path="/supplements"
            component={(props) => <RecommendationList {...props} />}
            exact
          />
          <Route path="/checkout">
            <Checkout {...this.props} />
          </Route>
          <Route path="/shipping">
            <ShippingForm {...this.props} />
          </Route>
          <Route path="/all_supplements">
            <SmartSearchSupplement {...this.props} exact />
          </Route>
          <Route
            path="/tenants/:tenant_id/catalog_list"
            component={(props) => <PatientScriptList {...props} />}
          />
          <Route path="/script_details">
            <ScriptDetails {...this.props} />
          </Route>
          <Route path="/order_details">
            <OrderDetails {...this.props} />
          </Route>
          <Route path="/review_order">
            <ReviewOrder {...this.props} />
          </Route>
          <Route
            path="/supplement_suggestions"
            component={(props) => <PatientRecommendation {...props} />}
            exact
          ></Route>
          <Route
            path="/view_recommended_script"
            component={(props) => <ViewRecommendedScreen {...props} />}
            exact
          ></Route>
          <Route
            path="/patient_script_list"
            component={(props) => <PatientScriptList {...props} />}
            exact
          ></Route>
          <Route
            path="/return_supplement_list"
            component={(props) => <ReturnSupplementList {...props} />}
            exact
          ></Route>
          <Route
            path="/review_return_items"
            component={(props) => <ReviewReturnItems {...props} />}
            exact
          ></Route>
          <Route
            path="/order/:orderId"
            component={(props) => <ShowOrderDetails {...props} />}
            exact
          ></Route>
          <Route
            path="/pre_login_catalog"
            component={(props) => <PreLoginCatalog {...props} />}
            exact
          ></Route>
          <Route
            path="/purepro_practice_order_and_scripts"
            component={(props) => <PracticeRecommendationList {...props} />}
            exact
          ></Route>
          <Route
            path="/purepro_patient_order_and_scripts"
            component={(props) => <PatientRecommendationList {...props} />}
            exact
          ></Route>
          <Route
            path="/practice_recommend_script"
            component={(props) => <RecommendationList {...props} />}
            exact
          />
          <Route
            path="/pg_practice_recommendation_script"
            component={(props) => <PgPatientRecommendationList {...props} />}
            exact
          ></Route>
          <Route
            path="/pg_patient_orders"
            component={(props) => <PatientScriptList {...props} />}
            exact
          ></Route>
          <Route
            path="/pg_patient_recommendations"
            component={(props) => <PatientRecommendationList {...props} />}
            exact
          ></Route>
          <Route
            path="/pg_practice_recommendations"
            component={(props) => <PracticeRecommendationList {...props} />}
            exact
          ></Route>
          <Route
            path="/pure_vd"
            exact
            render={(props) => <RialtoManagement {...props}/>}
          />
          <Route
            path={`/ppd_account`}
            component={(props) => <PpdAccount {...props} />}
            exact
          ></Route>
        </Switch>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div>
          <Suspense fallback={<Loading />}>{this.dynamic_routes()}</Suspense>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientDetail: state.patients.patientDetail,
    loaded: state.patients.detailLoaded,
  };
};

export default withRouter(connect(mapStateToProps)(App));
