import { RSAA } from "redux-api-middleware";
import * as actionTypes from "../constants/action-types";
import _ from "lodash";
import Cookies from "js-cookie";
// import { withAuth } from '../reducers';
import { setRialtoApiUrl } from "../components/utilities/utility";

const client_name = localStorage.getItem("client");
const BASE_URL = setRialtoApiUrl(client_name);



export const SHIPPING_REQUEST = "@@jwt/SHIPPING_REQUEST";
export const SHIPPING_SUCCESS = "@@jwt/SHIPPING_SUCCESS";
export const SHIPPING_FAILURE = "@@jwt/SHIPPING_FAILURE";

export const STATE_REQUEST = "@@jwt/STATE_REQUEST";
export const STATE_SUCCESS = "@@jwt/STATE_SUCCESS";
export const STATE_FAILURE = "@@jwt/STATE_FAILURE";

export const SHIPPING_METHOD_REQUEST = "@@jwt/SHIPPING_METHOD_REQUEST";
export const SHIPPING_METHOD_SUCCESS = "@@jwt/SHIPPING_METHOD_SUCCESS";
export const SHIPPING_METHOD_FAILURE = "@@jwt/SHIPPING_METHOD_FAILURE";

export const ADDRESS_TYPE_REQUEST = "@@jwt/ADDRESS_TYPE_REQUEST";
export const ADDRESS_TYPE_SUCCESS = "@@jwt/ADDRESS_TYPE_SUCCESS";
export const ADDRESS_TYPE_FAILURE = "@@jwt/ADDRESS_TYPE_FAILURE";

export const ORDERDETAIL_REQUEST = "@@jwt/ORDERDETAIL_REQUEST";
export const ORDERDETAIL_SUCCESS = "@@jwt/ORDERDETAIL_SUCCESS";
export const ORDERDETAIL_FAILURE = "@@jwt/ORDERDETAIL_FAILURE";

export const SCRIPTDATA_REQUEST = "@@jwt/SCRIPTDATA_REQUEST";
export const SCRIPTDATA_SUCCESS = "@@jwt/SCRIPTDATA_SUCCESS";
export const SCRIPTDATA_FAILURE = "@@jwt/SCRIPTDATA_FAILURE";

export const ORDERSTATE_REQUEST = "@@jwt/ORDERSTATE_REQUEST";
export const ORDERSTATE_SUCCESS = "@@jwt/ORDERSTATE_SUCCESS";
export const ORDERSTATE_FAILURE = "@@jwt/ORDERSTATE_FAILURE";

export const SHIPPING_METHOD_UPDATE_REQUEST =
	"@@jwt/SHIPPING_METHOD_UPDATE_REQUEST";
export const SHIPPING_METHOD_UPDATE_SUCCESS =
	"@@jwt/SHIPPING_METHOD_UPDATE_SUCCESS";
export const SHIPPING_METHOD_UPDATE_FAILURE =
	"@@jwt/SHIPPING_METHOD_UPDATE_FAILURE";

export const UPDATE_SHIPMENT_REQUEST = "@@jwt/UPDATE_SHIPMENT_REQUEST";
export const UPDATE_SHIPMENT_SUCCESS = "@@jwt/UPDATE_SHIPMENT_SUCCESS";
export const UPDATE_SHIPMENT_FAILURE = "@@jwt/UPDATE_SHIPMENT_FAILURE";

export const GET_SAVED_ADDRESS_REQUEST = "@@jwt/GET_SAVED_ADDRESS_REQUEST";
export const GET_SAVED_ADDRESS_SUCCESS = "@@jwt/GET_SAVED_ADDRESS_SUCCESS";
export const GET_SAVED_ADDRESS_FAILURE = "@@jwt/GET_SAVED_ADDRESS_FAILURE";

export const UPDATE_SCRIPT_REQUEST = "@@jwt/UPDATE_SCRIPT_REQUEST";
export const UPDATE_SCRIPT_SUCCESS = "@@jwt/UPDATE_SCRIPT_SUCCESS";
export const UPDATE_SCRIPT_FAILURE = "@@jwt/UPDATE_SCRIPT_FAILURE";

export const SHIPPINGPRICE_REQUEST = "@@jwt/SHIPPINGPRICE_REQUEST";
export const SHIPPINGPRICE_SUCCESS = "@@jwt/SHIPPINGPRICE_SUCCESS";
export const SHIPPINGPRICE_FAILURE = "@@jwt/SHIPPINGPRICE_FAILURE";

export const GET_SHIPPING_LIST_REQUEST = "@@jwt/GET_SHIPPING_LIST_REQUEST";
export const GET_SHIPPING_LIST_SUCCESS = "@@jwt/GET_SHIPPING_LIST_SUCCESS";
export const GET_SHIPPING_LIST_FAILURE = "@@jwt/GET_SHIPPING_LIST_FAILURE";

export const UPDATE_VALID_REQUEST = '@@jwt/UPDATE_VALID_REQUEST';
export const UPDATE_VALID_SUCCESS = '@@jwt/UPDATE_VALID_SUCCESS';
export const UPDATE_VALID_FAILURE = '@@jwt/UPDATE_VALID_FAILURE';

export const EMAIL_UPDATE_REQUEST = "@@jwt/EMAIL_UPDATE_REQUEST";
export const EMAIL_UPDATE_SUCCESS = "@@jwt/EMAIL_UPDATE_SUCCESS";
export const EMAIL_UPDATE_FAILURE = "@@jwt/EMAIL_UPDATE_FAILURE"

export const GET_DEFAULT_ADDRESS_REQUEST = "@@jwt/GET_DEFAULT_ADDRESS_REQUEST"
export const GET_DEFAULT_ADDRESS_SUCCESS = "@@jwt/GET_DEFAULT_ADDRESS_SUCCESS"
export const GET_DEFAULT_ADDRESS_FAILURE = "@@jwt/GET_DEFAULT_ADDRESS_FAILURE"

export const UPDATE_DEFAULT_ADDRESS_REQUEST = "@@jwt/UPDATE_DEFAULT_ADDRESS_REQUEST"
export const UPDATE_DEFAULT_ADDRESS_SUCCESS = "@@jwt/UPDATE_DEFAULT_ADDRESS_SUCCESS"
export const UPDATE_DEFAULT_ADDRESS_FAILURE = "@@jwt/UPDATE_DEFAULT_ADDRESS_FAILURE"

export const getShippingMethods = (rialtoClientToken) => {
	return {
		[RSAA]: {
			endpoint:
				BASE_URL +
				`/rialto/api/get_shipping_methods?rialto_token=${rialtoClientToken}`,
			method: "GET",
			headers: { "Content-Type": "application/json" },
			types: [
				SHIPPING_METHOD_REQUEST,
				SHIPPING_METHOD_SUCCESS,
				SHIPPING_METHOD_FAILURE,
			],
		},
	};
};

export const getAddressTypeList = () => {
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/get_address_types`,
			method: "GET",
			headers: { "Content-Type": "application/json" },
			types: [ADDRESS_TYPE_REQUEST, ADDRESS_TYPE_SUCCESS, ADDRESS_TYPE_FAILURE],
		},
	};
};

export const updateShippingAddress = (address_params, orderId, token) => {

	let rialto_client_token = Cookies.get('railto-token') || localStorage.getItem('railto-token')

	let address2 = address_params.address_line_2;
	if (typeof address2 === 'undefined') {
		address2 = "";
	}
	let params = {
		order: {
			email: address_params.email,
			ship_address_attributes: {
				first_name: address_params.first_name,
				last_name: address_params.last_name,
				name: address_params.first_name + " " + address_params.last_name,
				address1: address_params.address_line_1,
				address2: address2,
				city: address_params.city,
				country_id: 233,
				state_id: address_params.state_id,
				zipcode: address_params.zip_code,
				phone: address_params.phone,
				state_name: "California",
				address_type_id: address_params.address_type_id,
				save_address: address_params.save_address_flag,
			},
		},
	};
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/orders/${orderId}/`,
			method: "PATCH",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${rialto_client_token}`,
				"X-Spree-Order-Token": `${token}`,
			},
			types: [SHIPPING_REQUEST, SHIPPING_SUCCESS, SHIPPING_FAILURE],
		},
	};
};



export const getStatesList = () => {
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/countries/233/states`,
			method: "GET",
			headers: { "Content-Type": "application/json" },
			types: [STATE_REQUEST, STATE_SUCCESS, STATE_FAILURE],
		},
	};
};

export const getOrderData = (order_number) => {
	let rialto_client_token = Cookies.get('railto-token') || localStorage.getItem('railto-token')
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/orders/${order_number}/`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${rialto_client_token}`,
			},
			types: [ORDERDETAIL_REQUEST, ORDERDETAIL_SUCCESS, ORDERDETAIL_FAILURE],
		},
	};
};

export const getScriptData = (scriptId) => {
	let rialto_client_token = Cookies.get('railto-token') || localStorage.getItem('railto-token')
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/scripts/${scriptId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${rialto_client_token}`,
			},
			types: [SCRIPTDATA_REQUEST, SCRIPTDATA_SUCCESS, SCRIPTDATA_FAILURE],
		},
	};
};

export const orderUpdateState = (orderId, token) => {
	let rialto_client_token = Cookies.get('railto-token') || localStorage.getItem('railto-token')

	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/checkouts/${orderId}/next`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${rialto_client_token}`,
				"X-Spree-Order-Token": `${token}`,
			},
			types: [ORDERSTATE_REQUEST, ORDERSTATE_SUCCESS, ORDERSTATE_FAILURE],
		},
	};
};

export const orderNextState = (rialto_token, order_number, order_token) => {
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/checkouts/${order_number}/next`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${rialto_token}`,
				"X-Spree-Order-Token": `${order_token}`,
			},
			types: [ORDERSTATE_REQUEST, ORDERSTATE_SUCCESS, ORDERSTATE_FAILURE],
		},
	};
};

export const updateShippingMethod = (
	shippingMethodId,
	shipmentNumber,
	token
) => {

	let rialto_client_token = Cookies.get('railto-token') || localStorage.getItem('railto-token')

	let data = {
		shipping_method_id: shippingMethodId,
	};

	return {
		[RSAA]: {
			endpoint:
				BASE_URL +
				`/rialto/api/shipments/${shipmentNumber}/select_shipping_method`,
			method: "PUT",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${rialto_client_token}`,
				"X-Spree-Order-Token": `${token}`,
			},
			types: [
				SHIPPING_METHOD_UPDATE_REQUEST,
				SHIPPING_METHOD_UPDATE_SUCCESS,
				SHIPPING_METHOD_UPDATE_FAILURE,
			],
		},
	};
};

export const updateShipmentStockLocation = (orderId) => {
	let rialto_client_token = Cookies.get('railto-token') || localStorage.getItem('railto-token')

	let params = {
		order_id: orderId,
	};
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/update_shipment_stock_location/`,
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${rialto_client_token}`,
			},
			types: [
				UPDATE_SHIPMENT_REQUEST,
				UPDATE_SHIPMENT_SUCCESS,
				UPDATE_SHIPMENT_FAILURE,
			],
		},
	};
};

export const getShippingPrice = (scriptId, request_from, from_config) => {

	let rialto_client_token = localStorage.getItem('railto-token') || Cookies.get('railto-token') 

	let url = request_from
		? BASE_URL +
		`/rialto/api/shipping_price?script_id=${scriptId}&request_from=${request_from}`
		: BASE_URL + `/rialto/api/shipping_price?script_id=${scriptId}&from_config=${from_config}`;

	return {
		[RSAA]: {
			endpoint: url,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${rialto_client_token}`,
			},
			types: [
				SHIPPINGPRICE_REQUEST,
				SHIPPINGPRICE_SUCCESS,
				SHIPPINGPRICE_FAILURE,
			],
		},
	};
};

export const closeError = () => {
	return (dispatch, getState) => {
		dispatch({ type: "CLOSE_ERROR" });
	};
};

export const getUserSavedAddress = (rialtoToken, userId) => {
	return {
		[RSAA]: {
			endpoint:
				BASE_URL +
				`/rialto/api/user/addresses?rialto_token=${rialtoToken}`,
			method: "GET",
			headers: { "Content-Type": "application/json" },
			types: [
				GET_SAVED_ADDRESS_REQUEST,
				GET_SAVED_ADDRESS_SUCCESS,
				GET_SAVED_ADDRESS_FAILURE,
			],
		},
	};
};

export const updateScript = (scriptData, scriptId) => {
	let rialto_client_token =  localStorage.getItem('railto-token') || Cookies.get('railto-token')

	console.log(scriptData);
	let scriptIngredients = [];
	scriptData.script_variants.map((ing) => {
		let scriptObj = {
			supplement_id: ing.variant_id,
			supplement_code: ing.supplement_code,
			supplement_name: ing.supplement_name,
			suggested_dose_quantity: ing.suggested_dose_quantity,
			suggested_dose_frequency: ing.suggested_dose_frequency,
			suggested_dose_other_info: ing.suggested_dose_other_info,
			quantity: ing.quantity,
			id: ing.id,
			_destroy: ing.prop65 ? ing.prop65 : "",
		};
		scriptIngredients.push(scriptObj);
	});

	let params = {
		script: {
			script_ingredients_attributes: scriptIngredients,
		},
	};
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/update_script/` + scriptId,
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${rialto_client_token}`,
			},
			types: [
				UPDATE_SCRIPT_REQUEST,
				UPDATE_SCRIPT_SUCCESS,
				UPDATE_SCRIPT_FAILURE,
			],
		},
	};
};

// export const getShippingList = (orderId) => {
//   return {
//     [RSAA]: {
//       endpoint: BASE_URL + `/rialto/api/order/${orderId}/shipped_items`,
//       method: 'GET',
//       headers: { 'Content-Type': 'application/json' },
//       types: [
//         GET_SHIPPING_LIST_REQUEST, GET_SHIPPING_LIST_SUCCESS, GET_SHIPPING_LIST_FAILURE
//       ]
//     }
//   }
// }

// /rialto/api/create_script_with_valid_sku
export const updateValidSupplements = (validSupplments, scriptId, isOutStk) => {
	
	let rialto_client_token = Cookies.get('railto-token') || localStorage.getItem('railto-token')

	let params = {
		script_id: scriptId,
		valid_skus: validSupplments,
		out_of_stock: isOutStk
	}
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/create_script_with_valid_sku`,
			method: 'POST',
			body: JSON.stringify(params),
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${rialto_client_token}` },
			types: [
				UPDATE_VALID_REQUEST, UPDATE_VALID_SUCCESS, UPDATE_VALID_FAILURE
			]
		}
	}
}


export const updateOrderEmail = (email, orderId, token) => {

	let rialto_client_token = Cookies.get('railto-token') || localStorage.getItem('railto-token')

	let params = {
		order: {
			email: email
		}
	}
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/orders/${orderId}/`,
			method: "PATCH",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${rialto_client_token}`,
				"X-Spree-Order-Token": `${token}`,
			},
			types: [EMAIL_UPDATE_REQUEST, EMAIL_UPDATE_SUCCESS, EMAIL_UPDATE_FAILURE],
		},
	};
}

export const getDefaultAddress = (rialtoToken) => {
	return {
		[RSAA]: {
			endpoint:
				BASE_URL +
				`/rialto/api/get_default_user_address?rialto_token=${rialtoToken}`,
			method: "GET",
			headers: { "Content-Type": "application/json" },
			types: [
				GET_DEFAULT_ADDRESS_REQUEST,
				GET_DEFAULT_ADDRESS_SUCCESS,
				GET_DEFAULT_ADDRESS_FAILURE,
			],
		},
	};
}


export const updateDefaultAddress = (rialtoToken, addressValues) => {

	let rialto_client_token = Cookies.get('railto-token') || localStorage.getItem('railto-token')

	let params = {
		id: addressValues.id,
		first_name: addressValues.first_name,
		last_name: addressValues.last_name,
		address_1: addressValues.address_line_1,
		address_2: addressValues.address_line_2,
		city: addressValues.city,
		state: addressValues.state_id,
		zipcode: addressValues.zip_code,
		phone: addressValues.phone,
		country: "US",
		rialto_token: rialto_client_token
	}
	return {
		[RSAA]: {
			endpoint: BASE_URL + `/rialto/api/update_default_user_address`,
			method: 'POST',
			body: JSON.stringify(params),
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${rialto_client_token}` },
			types: [
				UPDATE_VALID_REQUEST, UPDATE_VALID_SUCCESS, UPDATE_VALID_FAILURE
			]
		}
	}
}
